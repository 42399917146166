import { Component, computed, inject } from '@angular/core';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { NgClass } from '@angular/common';
import { SnackbarService } from '../../Services/snackbar.service';
import { SnackbarConfig } from '../../models/snackbar-config';

@Component({
   selector: 'lib-snack-container',
   templateUrl: './snack-container.component.html',
   styleUrl: './snack-container.component.scss',
   standalone: true,
   imports: [SnackBarComponent, NgClass]
})
export class SnackContainerComponent {
   config = computed<SnackbarConfig>(() => this.snackbarService.globalConfig());
   isOpen = computed(() => this.snackbarService.isOpen());

   snackbarService = inject(SnackbarService);
}
