import { Component, inject, input } from '@angular/core';
import { Location, NgClass } from '@angular/common';
import { SnackbarService } from '../../Services/snackbar.service';
import { SnackbarConfig } from '../../models/snackbar-config';

@Component({
   selector: 'lib-snack-bar',
   templateUrl: './snack-bar.component.html',
   styleUrl: './snack-bar.component.scss',
   standalone: true,
   imports: [NgClass]
})
export class SnackBarComponent {
   config = input.required<SnackbarConfig>();

   location = inject(Location);
   snackBarService = inject(SnackbarService);

   goBack(): void {
      this.location.back();
   }

   close(): void {
      this.snackBarService.closeSnackBar();
   }

   actionClicked(): void {
      this.snackBarService.clickOnAction();
   }
}
